import axios from "axios";

// const baseURL = "http://localhost:3001" //Local baseURL
// const API = axios.create({ baseURL })
const API = axios.create({
  baseURL: "https://ibadu-rahman.onrender.com",
});

// API.interceptors.request.use((req) => {
//     if(localStorage.getItem('profile')) {
//         req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`
//     }
//     return req
// })

export const signIn = async (formData) =>
  await API.post("/api/v1/auth/sign-in", formData);
export const signUp = async (formData) =>
  await API.post("/api/v1/auth/sign-up", formData);
export const verifyUser = async (formData) =>
  await API.post("/api/v1/auth/verify-user", formData);

export const forgotPassword = async (formData) =>
  await API.post("/api/v1/auth/forgot-password", formData);
export const resetPassword = async (formData) =>
  await API.post("/api/v1/auth/reset-password", formData);
export const sendVerificationCode = async (formData) =>
  await API.post("/api/v1/auth/send-verification-code", formData);

export const getUserById = async (id) =>
  await API.get(`/api/v1/auth/users/${id}`);

// Admin dashboard
export const getAdminDashboard = async () =>
  await API.get("/api/v1/auth/admin-dashboard");

// Gallery
export const addGallery = async (formData) =>
  await API.post("/api/v1/add-gallery", formData);

export const updateGallery = async (formData) =>
  await API.put("/api/v1/update-gallery", formData);

export const getGalleryById = async (id) =>
  await API.get(`/api/v1/get-gallery/${id}`);

export const deleteGallery = async (id) =>
  await API.delete(`/api/v1/delete-gallery/${id}`);

export const getAllGalleries = async () =>
  await API.get("/api/v1/get-all-galleries");

export const getAllGalleriesByPage = async (page) =>
  await API.get(`/api/v1/get-all-galleries-by-page?page=${page}`);

export const getTotalNumberOfGalleries = async () =>
  await API.get("/api/v1/get-total-count-galleries");

// Post
export const addPost = async (formData) =>
  await API.post("/api/v1/add-post", formData);

export const updatePost = async (formData) =>
  await API.put("/api/v1/update-post", formData);

export const getPostById = async (id) =>
  await API.get(`/api/v1/get-post/${id}`);

export const deletePost = async (id) =>
  await API.delete(`/api/v1/delete-post/${id}`);

export const getAllPosts = async () => await API.get("/api/v1/get-all-posts");

export const getTotalNumberOfPosts = async () =>
  await API.get("/api/v1/get-total-count-posts");

export const getAllPostsByPage = async (page) =>
  await API.get(`/api/v1/get-all-posts-by-page?page=${page}`);

// Staff
export const addStaff = async (formData) =>
  await API.post("/api/v1/add-staff", formData);

export const updateStaff = async (formData) =>
  await API.put("/api/v1/update-staff", formData);

export const getStaffById = async (id) =>
  await API.get(`/api/v1/get-staff/${id}`);

export const deleteStaff = async (id) =>
  await API.delete(`/api/v1/delete-staff/${id}`);

export const getAllStaffs = async () => await API.get("/api/v1/get-all-staffs");

export const getAllStaffsByPage = async (page) =>
  await API.get(`/api/v1/get-all-staffs-by-page?page=${page}`);

export const getTotalNumberOfStaffs = async () =>
  await API.get("/api/v1/get-total-count-staffs");

export const imagekitAuthenticationEndpoint =
  "http://localhost:3000/imagekit/auth";
// "https://ibaadu-rahman.herokuapp.com/imagekit/auth";
export const imagekitUrlEndpoint = "https://ik.imagekit.io/gbyz72rxj";
export const imagekitPublicKey = "public_WqEZ0hOhoMpVTSl1dUkmI+B0Rus=";

// send message to mail
export const sendMessage = async (formData) =>
  await axios.post(
    "https://backend-email-sender-mailgun.herokuapp.com/send-message",
    formData
  );
