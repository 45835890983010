import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./ReadMore.css";
import { getPostById } from "../../api";

const ReadMore = () => {
  const [post, setPost] = useState({});

  const { postId } = useParams();

  useEffect(() => {
    getPostById(postId).then((response) => {
      const data = response.data.data;
      setPost(data);
    });

    return () => {};
  }, [postId]);

  return (
    <>
      <div className="signin_container">
        <div className="signin_word">
          <h1 className="signin_header">{post.title}</h1>
          <h3 className="signin_title">{post.description}</h3>
        </div>
        <div className="image_container">
          <img className="readmore_image" src={post.imageUrl} alt="" />
        </div>
        <div className="readmore_content_container">
          <p
            dangerouslySetInnerHTML={{ __html: post.content }}
            className="readmore_content"
          ></p>
        </div>
      </div>
    </>
  );
};

export default ReadMore;
