import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import "./StaffPage.css";
import { getAllStaffs } from "../../api";

const StaffPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [staffs, setStaffs] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    const loadStaffs = async () => {
      setIsLoading(true);
      try {
        const response = await getAllStaffs();
        const data = response.data.data;

        setStaffs((staffs) => [...staffs, ...data]);
        setErrorMsg("");
      } catch (error) {
        setErrorMsg("Error while loading data. Try again later.");
      }
      setIsLoading(false);
    };

    loadStaffs();
    return () => {};
  }, []);

  return (
    <Spin spinning={isLoading} size="large">
      <div className="staff_page">
        <h3 className="header">Our Staffs</h3>
        <div className="staff_card_container">
          {staffs.map((item, key) => (
            <div className="staff_card" key={key}>
              <div className="staff_page_image_container">
                <img src={item.imageUrl} alt="" className="staff_image" />
              </div>
              <div className="details_container">
                <h3 className="staff_name">{item.name}</h3>
                <p className="staff_position">{item.title}</p>
              </div>
            </div>
          ))}
        </div>
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}
      </div>
    </Spin>
  );
};

export default StaffPage;
