import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer/Footer";
import LandingPage from "../components/LandingPage/LandingPage";
import Announcement from "../components/AnnouncementPage/Announcement";
import StaffPage from "../components/StaffPage/StaffPage";
import GalleryPage from "../components/GalleryPage/GalleryPage";
import Signin from "../components/SignIn";
import ReadMore from "../components/ReadMorePage";

import AddAnnouncement from "../components/admin/AddAnnouncement/AddAnnouncement";
import AddGallery from "../components/admin/AddGallery";
import AddStaff from "../components/admin/AddStaff";
import UpdateGallery from "../components/admin/UpdateGallery";
import UpdateStaff from "../components/admin/UpdateStaff";
import Staffs from "../components/admin/Staffs";
import Galleries from "../components/admin/Galleries";
import Announcements from "../components/admin/Announcements";
import UpdateAnnouncement from "../components/admin/UpdateAnnouncement/UpdateAnnouncement";
import AdminHome from "../components/admin/AdminHome";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggleIsOpen={toggleIsOpen} />
      <Navbar isOpen={isOpen} toggleIsOpen={toggleIsOpen} />
      <Routes>
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/announcements" element={<Announcement />}></Route>
        <Route path="/staffs" element={<StaffPage />}></Route>
        <Route path="/gallery" element={<GalleryPage />}></Route>
        <Route path="/readmore/:postId" element={<ReadMore />}></Route>
        <Route path="/admin/signin" element={<Signin />}></Route>
        <Route path="/admin" element={<AdminHome />}></Route>
        <Route
          path="/admin/add-announcement"
          element={<AddAnnouncement />}
        ></Route>
        <Route path="/admin/add-gallery" element={<AddGallery />}></Route>
        <Route path="/admin/add-staff" element={<AddStaff />}></Route>
        <Route
          path="/admin/update-gallery/:id"
          element={<UpdateGallery />}
        ></Route>
        <Route path="/admin/update-staff/:id" element={<UpdateStaff />}></Route>
        <Route
          path="/admin/update-announcement/:id"
          element={<UpdateAnnouncement />}
        ></Route>
        <Route path="/admin/staffs" element={<Staffs />}></Route>
        <Route path="/admin/galleries" element={<Galleries />}></Route>
        <Route path="/admin/announcements" element={<Announcements />}></Route>
      </Routes>
      <Footer />
    </>
  );
};

export default Home;
