import React, { useState } from "react";
import "./LandingPage.css";
import Logo from "../../images/ibadu_rahman_logo.png";
import { ourServicesData, carouselImage } from "../../data";
import Swal from "sweetalert2";
import { Spin } from "antd";
import { sendMessage } from "../../api/apicalls";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import defaultImage from "../../images/default_profile_image.jpg";

const LandingPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    message: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      formData.fullName.length === 0 ||
      formData.phoneNumber.length === 0 ||
      formData.message.length === 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops. Kindly fill in all the fields.",
      });
    }

    const formToSubmit = {
      subject: "Message from www.ibadurahmanschool.com",
      sender: "adedejiyusuf26@gmail.com",
      receiver: "adedejiyusuf26@gmail.com",
      message: `<p>
        <h2>You have a message from www.ibadurahmanschool.com.</h2> <br/>
        <h2 style="color: blue;">Sender's Name:</h2> <h3> ${formData.fullName}</h3> 
        <h2 style="color: blue;">Sender's Phone Number:</h2> <h3> ${formData.phoneNumber}</h3>
        <h2 style="color: blue;">Message:</h2> <h3> ${formData.message}</h3> <br/>
        <h3>Kindly reply to the person.</h3>
      </p>`,
    };

    const response = await sendMessage(formToSubmit);
    if (response) {
      setFormData({
        fullName: "",
        phoneNumber: "",
        message: "",
      });
    }
    setIsLoading(false);
  };

  return (
    <Spin spinning={isLoading} size="large">
      <div className="landing_page">
        <div className="landing_page_header">
          <div className="landing_page_header_text">
            <p className="landing_page_header_description">Welcome to</p>
            <h3 className="landing_page_header_title">
              {" "}
              IBADU RAHMAN INTERNATIONAL SCHOOLS
            </h3>
            <p className="landing_page_header_description">
              A school of Islamic knowledge and teachings
            </p>
            <p className="landing_page_header_sub">
              CRECHE - NURSERY - PRIMARY - SECONDARY
            </p>
            <p className="landing_page_header_sub">
              MOTTO: Quest for Excellence
            </p>
            {/* <a href="/aboutus" className="button">
            Learn More
          </a> */}
          </div>
        </div>
        {/* About Us section */}
        <div className="about_us" id="about">
          <div className="row">
            <h3 className="subheading">About Us</h3>
            <p>
              Ibadu Rahman International Schools was founded on the 9th of
              October, 2009. It is a western-islamic academic setting formerly
              known as Al-Matin International School between the period of
              2009-2013 before the change of name to Ibadu Rahman International
              Schools in 2014.
            </p>
            <p>
              The school started with two teachers, three students and a
              building. The school got it's first approval (Basic) in the year
              2014 and the first LEC Examination was written in 2015, in this
              year the school began to offer admission to students into Junior
              Secondary School Classes. The approval of the Junior Secondary
              School was done in 2017 and students were able to write BEC
              Examination in the school since this year. Without any delay, the
              school got it's WAEC and NECO approval in the year 2019 and this
              made it possible for students to write their WASSCE and NECOSSCE
              in the school. The school held the first inter-house sport
              competition in 2013 and the first excursion was on 5th of April,
              2011. The school anthem to and pledge was composed by Alhaja
              Adesokan N.A. and Mrs. Morakinyo A.
            </p>
            <p>
              As the school retains its policies, which include instilling good
              moral conducts and Islamic practices in the students, memorization
              of the Holy Qur'an, Arabic classes and many more.
            </p>
            <p>
              Although, the school has faced the some challenges during the
              early years but with commitment, diligence and hard work, the
              school prevails and maintains its honour and values without
              bending any of it's rules or leaving any of its programmes out.
              Nevertheless, this citadel of learning has graduated tons of
              students out of the system and they are doing well in their
              various endeavors.
            </p>
            <p>
              Today, Ibadu Rahman remains the best Islamic school in its
              environment and has stands out in terms of academic, moral and
              spiritual upbringing of students. Aside from this, the school has
              in place:
            </p>
            <ul>
              <li>A conducive learning environment.</li>
              <li>Well equipped science laboratories.</li>
              <li>Qualified and experienced teachers.</li>
              <li>Hotels for boarding students.</li>
              <li>School buses for effective movement of students.</li>
              <li>
                Moral and spiritual sessions to nurture the students for the
                benefits of this world and the hereafter.
              </li>
            </ul>
            <p>
              Ibadu Rahman is not just a school but a home built to nurture
              tomorrow leaders.
            </p>
          </div>
        </div>
        {/* Our mission */}
        <div className="our_missions" id="mission">
          <div className="our_mission_container">
            <div className="row">
              <div className="card">
                <div className="mission__card">
                  <h3 className="mission__card__title">OUR VISION</h3>
                  <p>
                    To build future disciplined leaders. Pupils and students for
                    this worldly benefits and hereafter. And make the
                    organization as reference point for others.
                  </p>
                </div>
                <div className="mission__card">
                  <h3 className="mission__card__title">CORE VALUES</h3>
                  <div className="core_values">
                    <ul>
                      <li>Islam</li>
                      <li>Integrity</li>
                      <li>Modesty</li>
                      <li>Leadership</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card">
                <div className="mission__card">
                  <h3 className="mission__card__title">OUR MISSION</h3>
                  <p>
                    To offer qualitative and affordable education, preparing the
                    pupils and students for a successful career and good
                    leadership role. Giving them sound moral training to become
                    sensible, reasonable, reliable, responsible and accountable
                    leaders of tomorrow. Students and pupils who are God-fearing
                    and follow the footsteps of Rasullulah (Sallallahu alayhi
                    wasallam)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Our Services section */}
        <div className="our_services">
          <h3 className="our_services_subheading" id="services">
            OUR SERVICES
          </h3>
          <h3 className="our_services_description">Why Choose Us?</h3>
          <div className="our_services_container">
            {ourServicesData.map((item) => (
              <div className="card card2" key={item.id}>
                <p className="tag_container">{item.icon}</p>
                <h3>{item.title}</h3>
              </div>
            ))}
          </div>
        </div>
        {/* Contact us */}
        <div className="contact_us">
          <h3 className="subheading">CONTACT US</h3>
          <h3 className="landing_page_header_description">
            Do you have any enquiry?
          </h3>
          <div className="contact_us_container">
            <div className="contact_us_row">
              <h2>Send a text to us on WhatsApp</h2>
              <a
                href="https://wa.me/+2348053071325"
                target="_blank"
                className="button"
                rel="noreferrer"
              >
                Send text to WhatsApp
              </a>
              <h2>Call or text us on</h2>
              <h4>
                <ul>
                  <li>+234 (0) 806 929 3980</li>
                  <li>+234 (0) 805 307 1325</li>
                  <li>+234 (0) 803 713 6434</li>
                </ul>
              </h4>
            </div>
            <div className="contact_us_row">
              <div className="form_card">
                <h4>Or send a message to us on mail</h4>
                <br />
                <form action="" onSubmit={handleSubmit}>
                  <label>Enter your Full Name:</label> <br />
                  <input
                    name="fullName"
                    type="text"
                    className="input"
                    placeholder="Enter your Full Name"
                    onChange={handleChange}
                    // defaultValue={formData.fullName}
                    value={formData.fullName}
                  />
                  <br />
                  <label>Enter your Phone Number:</label> <br />
                  <input
                    name="phoneNumber"
                    type="number"
                    className="input"
                    placeholder="Enter your phone number"
                    onChange={handleChange}
                    // defaultValue={formData.phoneNumber}
                    value={formData.phoneNumber}
                  />
                  <br />
                  <label>Enter your Message:</label> <br />
                  <textarea
                    name="message"
                    id=""
                    cols="30"
                    rows="10"
                    className="text_area"
                    placeholder="Enter your message here"
                    onChange={handleChange}
                    // defaultValue={formData.message}
                    value={formData.message}
                  ></textarea>
                  <button className="button" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel_container">
          <Carousel
            swipeable={true}
            autoPlay={true}
            infiniteLoop={true}
            interval={1500}
            showThumbs={false}
          >
            {carouselImage.map((item, key) => (
              <div key={key}>
                <img src={item} alt="any" />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="carousel_container2">
          <h3 className="subheading" style={{ textAlign: "center" }}>
            TESTIMONIALS
          </h3>
          {/* <h3 className="landing_page_header_description">
          Do you have any enquiry?
        </h3> */}
          <Carousel
            swipeable={true}
            autoPlay={true}
            infiniteLoop={true}
            interval={10000}
            showThumbs={false}
          >
            {Array.from(new Array(10)).map((item, key) => (
              <div className="review_card" key={key}>
                <div className="user_image_container">
                  <img src={defaultImage} alt="" className="user_image" />
                </div>
                <p className="review_description">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Facilis reiciendis dolores consequuntur impedit accusantium in
                  saepe, ad vero non velit. Quo enim impedit nesciunt sint at,
                  eos delectus eligendi itaque sequi, pariatur alias repellendus
                  tempore ea cupiditate aspernatur architecto dolores magnam
                  reprehenderit omnis veritatis magni maiores dicta sed tempora.
                  Obcaecati.
                </p>
                <p className="review_name" style={{ fontWeight: "bold" }}>
                  Adedeji Yusuf
                </p>
                <p className="review_name" style={{ fontStyle: "italic" }}>
                  - Parent
                </p>
                <p className="review_name">22/05/2022</p>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </Spin>
  );
};

export default LandingPage;
