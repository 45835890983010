import React from "react";
import "./Footer.css";
import { BsFacebook, BsTwitter, BsInstagram } from "react-icons/bs";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_menu">
        <div className="footer_item">
          <div className="footer_item_menu">
            <p>
              <a className="footer_link" href="/#about">
                About us
              </a>
            </p>
          </div>
          <div className="footer_item_menu">
            <p>
              <a className="footer_link" href="#services">
                Our Services
              </a>
            </p>
            <p>
              <a className="footer_link" href="#mission">
                Our Mission
              </a>
            </p>
          </div>
          {/* <div className="footer_item_menu">
                    <p>Keep your finger on the pulse! 🔥 &nbsp; Sign up for our newsletter</p>
                    <input type="text" />
                </div> */}
        </div>
        <hr className="line" />
        <div className="footer_item">
          <div className="footer_item_menu">
            <p className="brand_name">
              <a className="footer_link" href="/">
                Ibadu Rahman
              </a>
            </p>
          </div>
          <div className="footer_item_menu">
            <p className="p_tag">
              Stay Connected &nbsp; &nbsp;{" "}
              <a className="footer_link" href="/">
                <BsFacebook />
              </a>{" "}
              &nbsp; &nbsp;{" "}
              <a className="footer_link" href="/">
                <BsTwitter />
              </a>{" "}
              &nbsp; &nbsp;{" "}
              <a className="footer_link" href="/">
                <BsInstagram />
              </a>{" "}
            </p>
          </div>
        </div>
        <p className="footer_all_right">
          Address 1: 57, Unity street, Papa Olorunda, Ibafo, Ogun state.
        </p>
        <p className="footer_all_right" style={{ maxWidth: "800px" }}>
          Address 2: 14, Ibadu Rahman Street, Peace Avenue, Behind Nigerian
          Sippers' Council Co-operative Estate, Okeiran, Off Omu Arogun Road,
          Mowe, Obafemi Owode L.G.A, Ogun State.
        </p>
        <p className="footer_all_right">
          Ibadu Rahman © {new Date().getFullYear()}. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
