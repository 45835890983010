import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { getAdminDashboard } from "../../../api";
import "./index.css";

const AdminHome = () => {
  const [adminDashboard, setAdminDashBoard] = useState({
    postCount: 0,
    galleryCount: 0,
    staffCount: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    getAdminDashboard()
      .then((res) => {
        setAdminDashBoard({
          staffCount: res.data.data.numberOfStaffs,
          galleryCount: res.data.data.numberOfGalleries,
          postCount: res.data.data.numberOfPosts,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const user = JSON.parse(localStorage.getItem("profile"));
  if (!user) window.location.replace("/");

  const redirect = () => {
    localStorage.clear();
    window.location.replace("/admin/signin");
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        window.location.replace("/admin/signin");
      }
    });
  };

  if (!user) {
    return (
      <>
        <div className="signin_container">
          <br /> <br />
          <h2>You are not an Admin, Login as an Admin to access this Page</h2>
          <h2>
            Click this <button onClick={redirect}>Link </button> to go to Login
            Page
          </h2>
        </div>
      </>
    );
  }

  if (user.user.role.toLowerCase() !== "admin") {
    return (
      <>
        <div className="signin_container">
          <br /> <br />
          <h2>You are not an Admin, Login as an Admin to access this Page</h2>
          <h2>
            Click this <button onClick={redirect}>Link </button> to go to Login
            Page
          </h2>
        </div>
      </>
    );
  }

  return (
    <Spin spinning={isLoading} size="large">
      <div className="admin_dashboard_container">
        <div className="admin_dashboard_row">
          <h3 className="title">Welcome back, Admin.</h3>
          <button onClick={handleLogout}>Logout</button>
        </div>

        <div className="box_container">
          <div className="">
            <Link to="/admin/staffs">
              <div className="box_div">
                <p>Total number of Staffs:</p>
                <h3>{adminDashboard.staffCount}</h3>
              </div>
            </Link>
            <Link to="/admin/add-staff">
              <button>Add new Staff</button>
            </Link>
          </div>
          <div className="">
            <Link to="/admin/announcements">
              <div className="box_div">
                <p>Total number of Announcement:</p>
                <h3>{adminDashboard.postCount}</h3>
              </div>
            </Link>
            <Link to="/admin/add-announcement">
              <button>Add new Post</button>
            </Link>
          </div>
          <div className="">
            <Link to="/admin/galleries">
              <div className="box_div">
                <p>Total number of Gallery:</p>
                <h3>{adminDashboard.galleryCount}</h3>
              </div>
            </Link>
            <Link to="/admin/add-gallery">
              <button>Add new gallery</button>
            </Link>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default AdminHome;
