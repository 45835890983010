import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import "./App.css";
import Home from "./pages";
import { arrowSVGText, svgStyle } from "./data";
import "antd/dist/antd.min.css";

function App() {
  return (
    <>
      <ScrollToTop
        smooth
        svgPath={arrowSVGText}
        color="white"
        width="500"
        height="500"
        viewBox="5 7 242 236"
        style={svgStyle}
      />
      <Router>
        <Home />
      </Router>
    </>
  );
}

export default App;
