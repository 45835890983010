import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import "./Announcement.css";
import { getAllPostsByPage } from "../../api";

const Announcement = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    const loadAnnouncement = async () => {
      try {
        setIsLoading(true);
        const response = await getAllPostsByPage(page);
        const data = response.data.data.data;

        setPosts((posts) => [...posts, ...data]);
        setErrorMsg("");
      } catch (error) {
        setErrorMsg("Error while loading data. Try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    loadAnnouncement();
  }, [page]);

  const loadMore = () => {
    setPage((page) => page + 1);
  };

  return (
    <Spin spinning={isLoading} size="large">
      <div className="announcement_page">
        <h3 className="header">
          Announcements &nbsp; <small className="news">(News)</small>
        </h3>
        <div className="news_container">
          {posts.map((item) => (
            <div className="news_card" key={item._id}>
              <div className="announcement_image_container">
                <img
                  src={item.imageUrl}
                  alt=""
                  className="announcement_image"
                />
              </div>
              <div className="details_container">
                <p className="title">{item.title}</p>
                <a className="anchor" href={"/readmore/" + item._id}>
                  Read more
                </a>
              </div>
            </div>
          ))}
        </div>
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}
        <div className="load-more">
          <button onClick={loadMore} className="button">
            {isLoading ? "Loading..." : "Load More"}
          </button>
        </div>
      </div>
    </Spin>
  );
};

export default Announcement;
