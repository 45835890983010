import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./AddAnnouncement.css";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Spin } from "antd";
import Swal from "sweetalert2";
import { addPost } from "../../../api/apicalls";

const AddAnnouncement = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [formData, setFormData] = useState({
    title: "",
    imageUrl: "",
    description: "",
    content: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const user = JSON.parse(localStorage.getItem("profile"));
  if (!user) window.location.replace("/");

  const onEditorStateChange = (editorState) => {
    setFormData({
      ...formData,
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
    return setEditorState(editorState);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      !formData.title ||
      !formData.imageUrl ||
      !formData.description ||
      !formData.content
    ) {
      setIsLoading(false);
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all fields and try again.",
      });
    }
    addPost(formData);
  };

  const redirect = () => {
    localStorage.clear();
    window.location.replace("/admin/signin");
  };

  if (!user) {
    return (
      <>
        <div className="signin_container">
          <br /> <br />
          <h2>You are not an Admin, Login as an Admin to access this Page</h2>
          <h2>
            Click this <button onClick={redirect}>Link </button> to go to Login
            Page
          </h2>
        </div>
      </>
    );
  }

  if (user.user.role.toLowerCase() !== "admin") {
    return (
      <>
        <div className="signin_container">
          <br /> <br />
          <h2>You are not an Admin, Login as an Admin to access this Page</h2>
          <h2>
            Click this <button onClick={redirect}>Link </button> to go to Login
            Page
          </h2>
        </div>
      </>
    );
  }

  return (
    <Spin spinning={isLoading} size="large">
      <div className="add_announcement_container">
        <Link to="/admin">
          <button>Back to Admin Home</button>
        </Link>
        <br />
        <h2>Add Announcement</h2>
        <label className="signin_label">Title</label>
        <input
          className="signin_input"
          type="text"
          placeholder="Enter the Title of Announcement"
          name="title"
          onChange={handleChange}
        />
        <label className="signin_label">Image URL</label>
        <input
          className="signin_input"
          type="text"
          placeholder="Enter the link to your image"
          name="imageUrl"
          onChange={handleChange}
        />
        <label className="signin_label">Description of the Announcement</label>
        <input
          className="signin_input"
          type="text"
          placeholder="Enter the description of the Announcement"
          name="description"
          onChange={handleChange}
        />
        <br />
        <label className="signin_label">Content of Announcement</label>

        <div className="editor">
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
          />
        </div>
        <br />
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </Spin>
  );
};

export default AddAnnouncement;
