import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Spin } from "antd";
import Swal from "sweetalert2";
import { updatePost } from "../../../api/apicalls";
import { getPostById } from "../../../api";

const UpdateAnnouncement = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [formData, setFormData] = useState({
    id: "",
    title: "",
    imageUrl: "",
    description: "",
    content: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    getPostById(id).then((response) => {
      const data = response.data.data;
      setFormData({
        id: data._id,
        title: data.title,
        imageUrl: data.imageUrl,
        description: data.description,
        content: data.content,
      });
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(`<p>${data.content}</p>`)
          )
        )
      );
      console.log(data);
    });

    return () => {};
  }, [id]);

  const user = JSON.parse(localStorage.getItem("profile"));
  if (!user) window.location.replace("/");

  const redirect = () => {
    localStorage.clear();
    window.location.replace("/admin/signin");
  };

  const onEditorStateChange = (editorState) => {
    setFormData({
      ...formData,
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
    return setEditorState(editorState);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      !formData.id ||
      !formData.title ||
      !formData.imageUrl ||
      !formData.description ||
      !formData.content
    ) {
      setIsLoading(false);
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all fields and try again.",
      });
    }
    updatePost(formData);
  };

  if (!user) {
    return (
      <>
        <div className="signin_container">
          <br /> <br />
          <h2>You are not an Admin, Login as an Admin to access this Page</h2>
          <h2>
            Click this <button onClick={redirect}>Link </button> to go to Login
            Page
          </h2>
        </div>
      </>
    );
  }

  if (user.user.role.toLowerCase() !== "admin") {
    return (
      <>
        <div className="signin_container">
          <br /> <br />
          <h2>You are not an Admin, Login as an Admin to access this Page</h2>
          <h2>
            Click this <button onClick={redirect}>Link </button> to go to Login
            Page
          </h2>
        </div>
      </>
    );
  }

  return (
    <Spin spinning={isLoading} size="large">
      <div className="add_announcement_container">
        <Link to="/admin">
          <button>Back to Admin Home</button>
        </Link>
        <br />
        <h2>Update Announcement</h2>
        <label className="signin_label">Title</label>
        <input
          className="signin_input"
          type="text"
          placeholder="Enter the Title of Announcement"
          name="title"
          onChange={handleChange}
          defaultValue={formData.title}
        />
        <label className="signin_label">Image URL</label>
        <input
          className="signin_input"
          type="text"
          placeholder="Enter the link to your image"
          name="imageUrl"
          onChange={handleChange}
          defaultValue={formData.imageUrl}
        />
        <label className="signin_label">Description of the Announcement</label>
        <input
          className="signin_input"
          type="text"
          placeholder="Enter the description of the Announcement"
          name="description"
          onChange={handleChange}
          defaultValue={formData.description}
        />
        <br />
        <label className="signin_label">Content of Announcement</label>

        <div className="editor">
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
          />
        </div>
        <br />
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </Spin>
  );
};

export default UpdateAnnouncement;
