import * as api from ".";
import Swal from "sweetalert2";

export const signIn = async (formData) => {
  try {
    const { data } = await api.signIn(formData);
    let responseData = data.data;

    localStorage.setItem("profile", JSON.stringify(responseData));

    // history.push('/')
    if (responseData) {
      Swal.fire("User Logged in Succesfully");
      setTimeout(() => {
        window.location.replace("/admin");
      }, 3000);
    }
    // window.location.replace("/")
  } catch (error) {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error.response.data.message}`,
      });
    }
    console.log(error);
  }
};

export const signUp = async (formData) => {
  try {
    const { data } = await api.signUp(formData);
    let responseData = data.data;

    if (responseData) {
      Swal.fire("User Created in Successfully");
      setTimeout(() => {
        window.location.replace("/");
      }, 3000);
    }

    // history.push('/')
    // window.location.replace("/")
  } catch (error) {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error.response.data.message}`,
      });
    }
    console.log(error);
  }
};

export const addPost = async (formData) => {
  try {
    const { data } = await api.addPost(formData);
    let responseData = data.data;

    // history.push('/')
    if (responseData) {
      Swal.fire("Announcement added Successfully").then(() => {
        window.location.replace("/admin/announcements");
      });
    }
    // window.location.replace("/")
  } catch (error) {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error.response.data.message}`,
      });
    }
    console.log(error);
  }
};

export const updatePost = async (formData) => {
  try {
    const { data } = await api.updatePost(formData);
    let responseData = data.data;

    // history.push('/')
    if (responseData) {
      Swal.fire("Announcement updated Successfully").then(() => {
        window.location.replace("/admin/announcements");
      });
    }
    // window.location.replace("/")
  } catch (error) {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error.response.data.message}`,
      });
    }
    console.log(error);
  }
};

export const deletePost = async (id) => {
  try {
    const { data } = await api.deletePost(id);
    let responseData = data.data;

    // history.push('/')
    if (responseData) {
      Swal.fire("Announcement deleted Successfully").then(() => {
        window.location.reload();
      });
    }

    // window.location.replace("/")
  } catch (error) {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error.response.data.message}`,
      });
    }
    console.log(error);
  }
};

export const addGallery = async (formData) => {
  try {
    const { data } = await api.addGallery(formData);
    let responseData = data.data;

    // history.push('/')
    if (responseData) {
      Swal.fire("Gallery added Successfully").then(() => {
        window.location.replace("/admin/galleries");
      });
    }
    // window.location.replace("/")
  } catch (error) {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error.response.data.message}`,
      });
    }
    console.log(error);
  }
};

export const updateGallery = async (formData) => {
  try {
    const { data } = await api.updateGallery(formData);
    let responseData = data.data;

    // history.push('/')
    if (responseData) {
      Swal.fire("Gallery updated Successfully").then(() => {
        window.location.replace("/admin/galleries");
      });
    }
    // window.location.replace("/")
  } catch (error) {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error.response.data.message}`,
      });
    }
    console.log(error);
  }
};

export const deleteGallery = async (id) => {
  try {
    const { data } = await api.deleteGallery(id);
    let responseData = data.data;

    if (responseData) {
      Swal.fire("Gallery deleted Successfully").then(() => {
        window.location.replace("/admin/galleries");
      });
    }
  } catch (error) {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error.response.data.message}`,
      });
    }
    console.log(error);
  }
};

export const addStaff = async (formData) => {
  try {
    const { data } = await api.addStaff(formData);
    let responseData = data.data;

    // history.push('/')
    if (responseData) {
      Swal.fire("Staff added Successfully").then(() => {
        window.location.replace("/admin/staffs");
      });
    }
    // window.location.replace("/")
  } catch (error) {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error.response.data.message}`,
      });
    }
    console.log(error);
  }
};

export const updateStaff = async (formData) => {
  try {
    const { data } = await api.updateStaff(formData);
    let responseData = data.data;

    // history.push('/')
    if (responseData) {
      Swal.fire("Staff updated Successfully").then(() => {
        window.location.replace("/admin/staffs");
      });
    }
    // window.location.replace("/")
  } catch (error) {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error.response.data.message}`,
      });
    }
    console.log(error);
  }
};

export const deleteStaff = async (id) => {
  try {
    const { data } = await api.deleteStaff(id);
    let responseData = data.data;

    if (responseData) {
      Swal.fire("Staff deleted Successfully").then(() => {
        window.location.reload();
      });
    }
  } catch (error) {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error.response.data.message}`,
      });
    }
    console.log(error);
  }
};

export const sendMessage = async (formData) => {
  try {
    const { data } = await api.sendMessage(formData);

    if (data) {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Thanks for reaching out to us. We will get back to you soon!",
      });
      return true;
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Try again later, if this error persists, please try reaching us on WhatsApp",
      });
      return false;
    }
  } catch (error) {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Try again later, if this error persists, please try reaching us on WhatsApp",
      });
      return false;
    }
    console.log(error);
  }
};
