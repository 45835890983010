import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import "./GalleryPage.css";
import { getAllGalleriesByPage } from "../../api";

const GalleryPage = () => {
  const [galleries, setGalleries] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    const loadGalleries = async () => {
      try {
        setIsLoading(true);
        const response = await getAllGalleriesByPage(page);
        const data = response.data.data.data;

        setGalleries((galleries) => [...galleries, ...data]);
        setErrorMsg("");
      } catch (error) {
        setErrorMsg("Error while loading data. Try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    loadGalleries();
    return () => {};
  }, [page]);

  const loadMore = () => {
    setPage((page) => page + 1);
  };

  return (
    <Spin spinning={isLoading} size="large">
      <div className="gallery_page">
        <h3 className="header">Our Gallery</h3>
        <div className="container">
          {galleries.map((item) => (
            <div class="card_container" key={item._id}>
              <img
                src={item.imageUrl}
                alt="Avatar"
                className="gallery_image_pic"
              />
              <div class="overlay">
                <p class="text">{item.title}</p>
              </div>
            </div>
          ))}
        </div>

        {errorMsg && <p className="errorMsg">{errorMsg}</p>}
        <div className="load-more">
          <button onClick={loadMore} className="button">
            {isLoading ? "Loading..." : "Load More"}
          </button>
        </div>
      </div>
    </Spin>
  );
};

export default GalleryPage;
