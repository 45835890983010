/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import { GoThreeBars } from "react-icons/go";
import {
  Nav,
  NavbarContainer,
  NavbarLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLink,
} from "./NavbarElements";
import logo from "../../images/ibadurahman_logo.png";
// import logo from '../../logo.svg'

// react component for the NavbarContainer
const Navbar = ({ isOpen, toggleIsOpen }) => {
  // return the NavbarContainer
  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavbarLogo to="/">
            <img src={logo} alt="" />
          </NavbarLogo>
          <MobileIcon onClick={toggleIsOpen}>
            <GoThreeBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLink to="/announcements">Announcements</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/gallery">Gallery</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/staffs">Our staffs</NavLink>
            </NavItem>
            {/* <NavItem>
                        <NavLink to="/">
                            <GrLanguage />&nbsp; Language
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/">
                            <AiOutlineShoppingCart />
                        </NavLink>
                    </NavItem> */}
          </NavMenu>
          {/* <NavBtn>
                    <NavLink to="/">
                        <MdOutlineLanguage />&nbsp; Language
                    </NavLink>
                    <NavLink to="/">
                        <ReactIcons>
                            <AiOutlineShoppingCart />
                        </ReactIcons>
                    </NavLink>
                    <NavBtnLink to="/">
                        Sign In
                    </NavBtnLink>
                </NavBtn> */}
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
