import React from "react";
// import { MdOutlineLanguage } from "react-icons/md";
// import { AiOutlineShoppingCart } from "react-icons/ai";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
} from "./SidebarElement";

// the react component
const Sidebar = ({ isOpen, toggleIsOpen }) => {
  // return the sidebar container
  return (
    <>
      <SidebarContainer isOpen={isOpen} onClick={toggleIsOpen}>
        <Icon onClick={toggleIsOpen}>
          <CloseIcon />
        </Icon>

        <SidebarWrapper>
          <SidebarMenu>
            <SidebarLink to="/announcements">Announcements</SidebarLink>
            <SidebarLink to="/gallery">Gallery</SidebarLink>
            <SidebarLink to="/staffs">Our Staffs</SidebarLink>
            {/* <SidebarLink to="/">
                       <MdOutlineLanguage />&nbsp; Language
                    </SidebarLink>
                    <SidebarLink to="/">
                        <AiOutlineShoppingCart />
                    </SidebarLink>
                    <SidebarLink to="/">
                        Sign Up
                    </SidebarLink> */}
          </SidebarMenu>
          {/* <SidebarBtn>
                    <SidebarRoute to="/">
                        Sign In
                    </SidebarRoute>
                </SidebarBtn> */}
        </SidebarWrapper>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
