import React, { useState } from "react";
import { Spin } from "antd";
import "./Signin.css";
import { signIn } from "../../api/apicalls";

const initialStateField = {
  email: "",
  password: "",
};

const Signin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialStateField);

  const user = JSON.parse(localStorage.getItem("profile"));
  if (user) window.location.replace("/admin");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await signIn(formData);
    setIsLoading(false);
  };
  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Spin spinning={isLoading} size="large">
      <div className="signin_container">
        <div className="signin_word">
          <h1 className="signin_header">Log in</h1>
          <h3 className="signin_title">Let’s get you back to your account!</h3>
        </div>

        <div>
          <form>
            <div>
              <label className="signin_label">Email</label>
              <input
                className="signin_input"
                type="email"
                placeholder="Enter your email address"
                name="email"
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="signin_label">Password</label>
              <input
                className="signin_input"
                type="password"
                placeholder="Enter your password"
                name="password"
                onChange={handleChange}
              />
            </div>
            <div className="signin_button_container">
              <button
                className="signin_button"
                type="submit"
                onClick={handleSubmit}
              >
                Log in
              </button>
            </div>
          </form>
        </div>
        {/* <div className="have_account">
        <h3>
          Don’t have an account?{" "}
          <a href="/" className="login_text">
            Sign up here.
          </a>
        </h3>
      </div> */}
      </div>
    </Spin>
  );
};

export default Signin;
