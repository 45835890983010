import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Spin } from "antd";

import "./UpdateStaff.css";
import { updateStaff } from "../../../api/apicalls";
import { getStaffById } from "../../../api";

const UpdateStaff = () => {
  const { id } = useParams();

  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getStaffById(id).then((response) => {
      const data = response.data.data;
      setFormData({
        id: data._id,
        name: data.name,
        title: data.title,
        imageUrl: data.imageUrl,
      });
    });

    return () => {};
  }, [id]);

  const user = JSON.parse(localStorage.getItem("profile"));

  const redirect = () => {
    localStorage.clear();
    window.location.replace("/admin/signin");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      !formData.id ||
      !formData.name ||
      !formData.title ||
      !formData.imageUrl
    ) {
      setIsLoading(false);
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all fields and try again.",
      });
    }
    await updateStaff(formData);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  if (!user) {
    return (
      <>
        <div className="signin_container">
          <br /> <br />
          <h2>You are not an Admin, Login as an Admin to access this Page</h2>
          <h2>
            Click this <button onClick={redirect}>Link </button> to go to Login
            Page
          </h2>
        </div>
      </>
    );
  }

  if (user.user.role.toLowerCase() !== "admin") {
    return (
      <>
        <div className="signin_container">
          <br /> <br />
          <h2>You are not an Admin, Login as an Admin to access this Page</h2>
          <h2>
            Click this <button onClick={redirect}>Link </button> to go to Login
            Page
          </h2>
        </div>
      </>
    );
  }

  return (
    <Spin spinning={isLoading} size="large">
      <div className="signin_container">
        <div className="signin_word">
          <Link to="/admin">
            <button>Back to Admin Home</button>
          </Link>
          <br />
          <h1 className="signin_header">Update Staff</h1>
          <h3 className="signin_title">You can update staff profile here</h3>
        </div>

        <div>
          <form>
            <div>
              <label className="signin_label">Name</label>
              <input
                className="signin_input"
                type="text"
                placeholder="Enter the name of staff"
                name="name"
                defaultValue={formData.name}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="signin_label">Title </label> <br />
              <label className="signin_label">
                <small>Post held</small>
              </label>
              <input
                className="signin_input"
                type="text"
                placeholder="Enter the post of the staff"
                name="title"
                defaultValue={formData.title}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="signin_label">Image URL</label> <br />
              <label className="signin_label">
                <small>Image of the person</small>
              </label>
              <input
                className="signin_input"
                type="text"
                placeholder="Enter link to image"
                name="imageUrl"
                defaultValue={formData.imageUrl}
                onChange={handleChange}
              />
            </div>
            <div className="signin_button_container">
              <button
                className="signin_button"
                type="submit"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        {/* <div className="have_account">
        <h3>
          Don’t have an account?{" "}
          <a href="/" className="login_text">
            Sign up here.
          </a>
        </h3>
      </div> */}
      </div>
    </Spin>
  );
};

export default UpdateStaff;
