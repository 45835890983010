import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Spin } from "antd";
import "./AddStaff.css";
import { addStaff } from "../../../api/apicalls";
// import { IKImage, IKContext, IKUpload } from "imagekitio-react";
// import {
//   imagekitUrlEndpoint,
//   imagekitAuthenticationEndpoint,
//   imagekitPublicKey,
// } from "../../../api";

const initialStateField = {
  title: "",
  imageUrl: "",
  name: "",
};

const AddStaff = () => {
  const [formData, setFormData] = useState(initialStateField);
  const [isLoading, setIsLoading] = useState(false);

  const user = JSON.parse(localStorage.getItem("profile"));

  const redirect = () => {
    localStorage.clear();
    window.location.replace("/admin/signin");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!formData.name || !formData.title || !formData.imageUrl) {
      setIsLoading(false);
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all fields and try again.",
      });
    }
    await addStaff(formData);
  };
  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // const onError = (error) => {
  //   console.log(error);
  // };
  // const onSuccess = (success) => {
  //   console.log(success);
  // };
  if (!user) {
    return (
      <>
        <div className="signin_container">
          <br /> <br />
          <h2>You are not an Admin, Login as an Admin to access this Page</h2>
          <h2>
            Click this <button onClick={redirect}>Link </button> to go to Login
            Page
          </h2>
        </div>
      </>
    );
  }

  if (user.user.role.toLowerCase() !== "admin") {
    return (
      <>
        <div className="signin_container">
          <br /> <br />
          <h2>You are not an Admin, Login as an Admin to access this Page</h2>
          <h2>
            Click this <button onClick={redirect}>Link </button> to go to Login
            Page
          </h2>
        </div>
      </>
    );
  }

  return (
    <Spin spinning={isLoading} size="large">
      <div className="signin_container">
        <div className="signin_word">
          <Link to="/admin">
            <button>Back to Admin Home</button>
          </Link>
          <br />
          <h1 className="signin_header">Add Staff</h1>
          <h3 className="signin_title">You can add staff here</h3>
        </div>

        <div>
          <form>
            <div>
              <label className="signin_label">Name</label>
              <input
                className="signin_input"
                type="text"
                placeholder="Enter the name of staff"
                name="name"
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="signin_label">Title </label> <br />
              <label className="signin_label">
                <small>Post held</small>
              </label>
              <input
                className="signin_input"
                type="text"
                placeholder="Enter the post of the staff"
                name="title"
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="signin_label">Image URL</label> <br />
              <label className="signin_label">
                <small>Image of the person</small>
              </label>
              <input
                className="signin_input"
                type="text"
                placeholder="Enter link to image"
                name="imageUrl"
                onChange={handleChange}
              />
            </div>
            {/* <IKContext
              publicKey={imagekitPublicKey}
              urlEndpoint={imagekitUrlEndpoint}
              authenticationEndpoint={imagekitAuthenticationEndpoint}
            >
              <IKUpload onError={onError} onSuccess={onSuccess} />
            </IKContext> */}
            {/* <input type="file" name="" id="" onChange={()} /> */}
            <div className="signin_button_container">
              <button
                className="signin_button"
                type="submit"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        {/* <div className="have_account">
        <h3>
          Don’t have an account?{" "}
          <a href="/" className="login_text">
            Sign up here.
          </a>
        </h3>
      </div> */}
      </div>
    </Spin>
  );
};

export default AddStaff;
