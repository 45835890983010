import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Spin } from "antd";
import Swal from "sweetalert2";
import "./Staffs.css";
import { deleteStaff } from "../../../api/apicalls";
import { getAllStaffs } from "../../../api";

const Staffs = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [staffs, setStaffs] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getAllStaffs().then((response) => {
      const data = response.data.data;
      setStaffs(data);
      setIsLoading(false);
    });

    return () => {};
  }, []);

  const user = JSON.parse(localStorage.getItem("profile"));

  const redirect = () => {
    localStorage.clear();
    window.location.replace("/admin/signin");
  };

  const deleteStaffById = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteStaff(id);
      }
    });
  };

  if (!user) {
    return (
      <>
        <div className="signin_container">
          <br /> <br />
          <h2>You are not an Admin, Login as an Admin to access this Page</h2>
          <h2>
            Click this <button onClick={redirect}>Link </button> to go to Login
            Page
          </h2>
        </div>
      </>
    );
  }

  if (user.user.role.toLowerCase() !== "admin") {
    return (
      <>
        <div className="signin_container">
          <br /> <br />
          <h2>You are not an Admin, Login as an Admin to access this Page</h2>
          <h2>
            Click this <button onClick={redirect}>Link </button> to go to Login
            Page
          </h2>
        </div>
      </>
    );
  }

  return (
    <Spin spinning={isLoading} size="large">
      <div className="signin_container">
        <Link to="/admin">
          <button>Back to Admin Home</button>
        </Link>
        <div className="signin_word">
          <h1 className="signin_header">All Staffs</h1>
          <h3 className="signin_title">Manage all staffs on this page</h3>
        </div>

        <div>
          <table>
            <tbody>
              <tr>
                <th>Name</th>
                <th>Title</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
              {staffs.map((item, key) => (
                <tr key={key}>
                  <td>{item.name}</td>
                  <td>{item.title}</td>
                  <td>
                    <a
                      className="anchor"
                      href={"/admin/update-staff/" + item._id}
                    >
                      Edit
                    </a>
                  </td>
                  <td>
                    {" "}
                    <button onClick={() => deleteStaffById(item._id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Spin>
  );
};

export default Staffs;
