import { GiOpenBook, GiPowerGenerator } from "react-icons/gi";
import { MdLanguage } from "react-icons/md";
import { BiPlusMedical, BiLibrary } from "react-icons/bi";
import { AiFillEnvironment } from "react-icons/ai";
import { RiComputerFill } from "react-icons/ri";
import { MdScience } from "react-icons/md";
import { FaBusAlt, FaUserCheck } from "react-icons/fa";
import { TbYoga } from "react-icons/tb";

import image1 from "./images/ibadu_rahman_carousel/image1.jpeg";
import image2 from "./images/ibadu_rahman_carousel/image2.jpeg";
import image3 from "./images/ibadu_rahman_carousel/image3.jpeg";
import image4 from "./images/ibadu_rahman_carousel/image4.jpeg";
import image5 from "./images/ibadu_rahman_carousel/image5.jpeg";
import image6 from "./images/ibadu_rahman_carousel/image6.jpeg";
import image7 from "./images/ibadu_rahman_carousel/image7.jpeg";
import image8 from "./images/ibadu_rahman_carousel/image8.jpeg";
import image9 from "./images/ibadu_rahman_carousel/image9.jpeg";
import image10 from "./images/ibadu_rahman_carousel/image10.jpeg";
import image11 from "./images/ibadu_rahman_carousel/image11.jpeg";
import image12 from "./images/ibadu_rahman_carousel/image12.jpeg";
import image13 from "./images/ibadu_rahman_carousel/image13.jpeg";
import image14 from "./images/ibadu_rahman_carousel/image14.jpeg";

export const ourServicesData = [
  {
    id: 1,
    icon: <GiOpenBook />,
    title: "Quran Memorization",
  },
  {
    id: 2,
    icon: <MdLanguage />,
    title: "Arabic Language",
  },
  {
    id: 3,
    icon: <FaUserCheck />,
    title: "Quality & Skilled staffs",
  },
  {
    id: 4,
    icon: <AiFillEnvironment />,
    title: "Conducive environment",
  },
  {
    id: 5,
    icon: <RiComputerFill />,
    title: "Computer Laboratory",
  },
  {
    id: 6,
    icon: <BiLibrary />,
    title: "Library",
  },
  {
    id: 7,
    icon: <MdScience />,
    title: "Science Laboratory",
  },
  {
    id: 8,
    icon: <BiPlusMedical />,
    title: "Medical Laboratory",
  },
  {
    id: 9,
    icon: <GiPowerGenerator />,
    title: "Standby Generator",
  },
  {
    id: 10,
    icon: <FaBusAlt />,
    title: "School bus",
  },
  {
    id: 11,
    icon: <TbYoga />,
    title: "Taekwando",
  },
];

export const carouselImage = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
];

export const arrowSVGText =
  "m16.843 13.789c.108.141.157.3.157.456 0 .389-.306.755-.749.755h-8.501c-.445 0-.75-.367-.75-.755 0-.157.05-.316.159-.457 1.203-1.554 3.252-4.199 4.258-5.498.142-.184.36-.29.592-.29.23 0 .449.107.591.291 1.002 1.299 3.044 3.945 4.243 5.498z";
export const svgStyle = {
  backgroundColor: "black",
  right: "15px",
  bottom: "15px",
  borderRadius: "50%",
  padding: "revert",
};
